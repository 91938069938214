import useBrowserApiHelpers from '@/composables/use-browser-api-helpers';
import { setCookie } from '@/helpers/cookies';
import parsedLocalStorage from '@/helpers/parsedLocalStorage';

const COOKIE_NAME = 'v-pvars';
const HASH_STORAGE_KEY = 'pvarsHash';
const MAX_HISTORY_LENGTH = 25;
const STORAGE_KEY = 'pvars';

let lastPvarsHash = null;
let pvarsInitialized = false;

function sanitize(value) {
  if (typeof value !== 'string') {
    return '';
  }

  const sanitized = value
    .toString()
    .toLowerCase()
    .replace(/[<>"'&]/g, '')
    .trim();

  return sanitized.length > 100
    ? sanitized.slice(0, 100)
    : sanitized;
}

// Because `crypto` is a forbidden pig package
// Use large prime modulus to keep 32-big integer
function generateHash(obj) {
  const str = JSON.stringify(obj);
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash * 31 + char) % 1000000007;
  }

  return hash;
}

export default function usePvarsStorage() {
  const { localStorageAvailable } = useBrowserApiHelpers();

  const baseKeyVal = (key, val) => {
    const sVal = sanitize(val);

    return sVal.length > 0
      ? { key, value: sVal }
      : null;
  };

  const paramToPvarMapping = {
    utm_medium: (val) => {
      const sVal = sanitize(val);

      return sVal && ['paid', 'ppc', 'cpc'].includes(sVal)
        ? { key: 'utm_medium', value: 'ppc' }
        : null;
    },
    utm_campaign: (val) => baseKeyVal('utm_campaign', val),
    utm_source: (val) => baseKeyVal('utm_source', val),
    utm_term: (val) => baseKeyVal('utm_term', val),
    utm_content: (val) => baseKeyVal('utm_content', val),
    ovar: (val) => baseKeyVal('ovar', val),
  };

  const parsePvarsFromUrl = () => {
    if (!localStorageAvailable()) {
      return [];
    }

    const urlParams = new URLSearchParams(window.location.search);
    const pvars = [];

    Object.keys(paramToPvarMapping).forEach((param) => {
      const val = urlParams.get(param);

      if (typeof val === 'string' && val.length > 0) {
        const pvarEntry = paramToPvarMapping[param](val);

        if (pvarEntry) {
          pvars.push(pvarEntry);
        }
      }
    });

    return pvars;
  };

  const setPvar = (key, value) => {
    if (!localStorageAvailable()) {
      return;
    }

    const safeKey = sanitize(key);
    const safeValue = sanitize(value);

    if (!safeKey || safeKey.length === 0) {
      return;
    }

    if (!safeValue || safeValue.length === 0) {
      return;
    }

    const pvarsData = parsedLocalStorage(STORAGE_KEY) || {};

    if (!Array.isArray(pvarsData[safeKey])) {
      pvarsData[safeKey] = [];
    }

    pvarsData[safeKey].unshift({
      value: safeValue,
      date: new Date().toISOString(),
    });

    if (pvarsData[safeKey].length > MAX_HISTORY_LENGTH) {
      pvarsData[safeKey].splice(MAX_HISTORY_LENGTH);
    }

    localStorage.setItem(STORAGE_KEY, JSON.stringify(pvarsData));
  };

  /*
  function getPvar(key) {
    if (!localStorageAvailable() || !key) {
      return null;
    }

    const safeKey = sanitize(key);

    if (!safeKey) {
      return null;
    }

    const pvarsData = parsedLocalStorage(STORAGE_KEY);

    if (!pvarsData || !Array.isArray(pvarsData[safeKey]) || pvarsData[safeKey].length === 0) {
      return null;
    }

    const entries = pvarsData[safeKey];

    return entries.length > 0
      ? entries[0].value
      : null;
  }
  */

  const latestPvars = () => {
    if (!localStorageAvailable()) {
      return {};
    }

    const pvarsData = parsedLocalStorage(STORAGE_KEY);

    if (!pvarsData) {
      return {};
    }

    const latestValues = {};

    Object.keys(pvarsData).forEach((key) => {
      const entries = pvarsData[key];

      if (Array.isArray(entries) && entries.length > 0) {
        const latestEntry = entries[0];
        const safeKey = sanitize(key);
        const prefixedKey = `pvar_${safeKey}`;
        const prefixedDateKey = `pvar_${safeKey}_date`;
        const safeValue = sanitize(latestEntry.value);
        const safeDate = new Date(latestEntry.date).toISOString();

        if (safeValue.length > 0) {
          latestValues[prefixedKey] = safeValue;
          latestValues[prefixedDateKey] = safeDate;
        }
      }
    });

    return latestValues;
  };

  const removePvars = () => {
    if (localStorageAvailable()) {
      localStorage.removeItem(STORAGE_KEY);
      localStorage.removeItem(HASH_STORAGE_KEY);
      lastPvarsHash = null;
    }
  };

  function persistToCookie() {
    const pvars = latestPvars();

    if (Object.keys(pvars).length > 0) {
      const currentHash = generateHash(pvars);
      const storedHash = localStorage.getItem(HASH_STORAGE_KEY);
      const parsedStoredHash = storedHash ? parseInt(storedHash, 10) : null;
      const effectiveLastHash = parsedStoredHash !== null ? parsedStoredHash : lastPvarsHash;

      if (currentHash !== effectiveLastHash) {
        const encodedPvars = btoa(JSON.stringify(pvars));
        setCookie(COOKIE_NAME, encodedPvars);
        lastPvarsHash = currentHash;

        localStorage.setItem(HASH_STORAGE_KEY, currentHash.toString());
      }
    }
  }

  const initializePvars = () => {
    if (pvarsInitialized) {
      return;
    }

    const storedHash = localStorage.getItem(HASH_STORAGE_KEY);
    if (storedHash) {
      lastPvarsHash = parseInt(storedHash, 10);
    }

    const pvars = parsePvarsFromUrl();
    if (pvars && pvars.length > 0) {
      pvars.forEach((pvar) => setPvar(pvar.key, pvar.value));
    }

    persistToCookie();

    if (lastPvarsHash === null) {
      const initialPvars = latestPvars();
      lastPvarsHash = generateHash(initialPvars);
      localStorage.setItem(HASH_STORAGE_KEY, lastPvarsHash.toString());
    }

    pvarsInitialized = true;
  };

  initializePvars();

  return {
    latestPvars,
    removePvars,
  };
}
