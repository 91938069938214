<script>
import sizes from '@/helpers/buttons/sizes';
import variants from '@/helpers/buttons/variants';
import CommonAttributes from '@/mixins/CommonAttributes';

export default {
  name: 'VetsterButtonLegacy',

  mixins: [CommonAttributes],

  props: {
    tagName: {
      type: String,
      default: 'button',
      validator(value) {
        return ['button', 'a'].includes(value);
      },
    },
    value: {
      type: [String, Number],
      default: null,
    },
    type: {
      type: String,
      default: 'button',
    },
    href: {
      type: String,
      default: null,
    },
    data: {
      type: Object,
      default: undefined,
    },
    preserveState: {
      type: Boolean,
      default: false,
    },
    preserveScroll: {
      type: Boolean,
      default: false,
    },
    to: {
      type: [String, Object],
      default: undefined,
    },
    replace: {
      type: Boolean,
      default: false,
    },
    append: {
      type: Boolean,
      default: false,
    },
    activeClass: {
      type: String,
      default: 'router-link-active',
    },
    exact: {
      type: Boolean,
      default: false,
    },
    exactActiveClass: {
      type: String,
      default: 'router-link-exact-active',
    },
    method: {
      type: String,
      default: undefined,
    },
    variant: {
      type: String,
      default: undefined,
    },
    size: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    /**
     * The default classes for the button
     *
     * @return {Array}
     */
    currentClass() {
      const classes = [variants.base, sizes.base];

      if (this.disabled) {
        classes.push(variants.disabled);
      }

      if (variants[this.variant]) {
        classes.push(variants[this.variant]);
      }

      if (sizes[this.size]) {
        classes.push(sizes[this.size]);
      }

      return classes;
    },

    isRouterLinkComponentAvailable() {
      return !!(
        this.$options.components.RouterLink || this.$options.components.NuxtLink
      );
    },

    /**
     * If we have the `to` defined and the routerLink or Nuxt link component is available we can
     * use the create a router link
     *
     * @return {Boolean}
     */
    isARouterLink() {
      return this.to !== undefined && this.isRouterLinkComponentAvailable;
    },

    /**
     * The component to render according to the props
     * @return {String}
     */
    componentToRender() {
      if (this.isARouterLink) {
        return this.$options.components.NuxtLink
          || this.$options.components.RouterLink;
      }

      if (this.href) {
        return 'a';
      }

      return this.tagName;
    },
  },

  methods: {
    onBlur(e) {
      this.$emit('blur', e);
    },

    onFocus(e) {
      this.$emit('focus', e);
    },

    onClick(e) {
      this.$emit('click', e);
    },

    blur() {
      this.$el.blur();
    },

    focus() {
      this.$el.focus();
    },

    getAttributes() {
      if (this.isARouterLink) {
        return {
          to: this.to,
          replace: this.replace,
          append: this.append,
          tag: this.tagName,
          activeClass: this.activeClass,
          exact: this.exact,
          event: ['click', 'focus', 'blur'],
          exactActiveClass: this.exactActiveClass,
          id: this.id,
          value: this.value,
          autofocus: this.autofocus,
          disabled: this.disabled,
          name: this.name,
          type: this.type,
        };
      }

      return {
        id: this.id,
        value: this.value,
        autofocus: this.autofocus,
        disabled: this.disabled,
        name: this.name,
        href: this.href,
        type: this.type,
      };
    },
  },

  render(createElement) {
    return createElement(
      this.componentToRender,
      {
        attrs: this.getAttributes(),
        class: this.currentClass,
        on: {
          click: this.onClick,
          focus: this.onFocus,
          blur: this.onBlur,
        },
      },
      this.$slots.default,
    );
  },
};
</script>
