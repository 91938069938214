import merge from 'lodash/merge';

import Designation from '@/enums/Designation';
import OrganizationName from '@/enums/OrganizationName';
import UserType from '@/enums/UserType';
import Address from '@/models/Address';

// Definition originates from `vetster-api`:app/Http/Resources/User.php
// This file should stay synced with `vetster-web`:models/User.js
// This file should stay synced with `vetster-admin-app`:models/User.js
// This file should stay synced with `vetster-web-app`:models/User.js
class User {
  constructor(data) {
    this.profile_address = {};
    this.base_availability = {};
    this.birthday_on = '';
    this.auth_id = '';
    this.created_at = '';
    this.designation = '';
    this.post_nominal = '';
    this.email = '';
    this.email_hash_sha1 = '';
    this.first_name = '';
    this.gender = '';
    this.type = '';
    this.is_geography_restricted = false;
    this.last_name = '';
    this.licenses = {};
    this.locale = '';
    this.pets = {};
    this.phone = {};
    this.photo = {};
    this.rate = {};
    this.clinic = {};
    this.school_name = '';
    this.school_year = '';
    this.signature = '';
    this.status = '';
    this.timezone = '';
    this.stripe_account = {};
    this.website_url = '';
    this.uuid = '';
    this.vi_message = '';

    if (data?.profile_address) {
      this.profile_address = new Address(data.profile_address);
    }

    if (data) {
      merge(this, data);
    }
  }

  initials() {
    return `${this.first_name?.charAt(0)}${this.last_name?.charAt(0)}`;
  }

  formalName() {
    return `${this.isVet() ? `${this.honorific()} ${this.last_name}` : this.fullName()}`.trim();
  }

  fullDesignation() {
    return [
      this.isVet() ? 'Veterinarian' : 'Technician',
      this.post_nominal,
    ].filter(Boolean).join(', ');
  }

  fullName() {
    return `${this.first_name} ${this.last_name}`.trim();
  }

  fullNameWithDesignation() {
    if (this.isPartner()) {
      return `${this.fullNameWithTitle()}, ${this.postNominal()}`.trim();
    }

    return this.fullName();
  }

  fullNameWithTitle() {
    return `${this.honorific()} ${this.fullName()}`.trim();
  }

  honorific() {
    return this.isVet() ? 'Dr.' : '';
  }

  isClient() {
    return this.type === UserType.Client;
  }

  isFetch() {
    return this.organization?.name === OrganizationName.Fetch;
  }

  isGlobal() {
    return !!this.locale_override;
  }

  isPartner() {
    return this.type === UserType.Partner;
  }

  isPetmeds() {
    return this.organization?.name === OrganizationName.PetMeds;
  }

  isTech() {
    return this.designation === Designation.Technician;
  }

  isVet() {
    return this.designation === Designation.Veterinarian;
  }

  postNominal() {
    return this.post_nominal || (this.isVet() ? 'DVM' : 'RVT');
  }
}

export default User;
