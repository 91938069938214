export default function useBrowserApiHelpers() {
  const localStorageAvailable = (type = 'localStorage') => {
    // Check if we are in a browser environment
    if (typeof window === 'undefined') {
      return false;
    }

    let storage;
    try {
      // Ensure the desired storage type exists on window
      if (!(type in window)) {
        return false;
      }

      storage = window[type];
      const testKey = '__storage_test__';
      storage.setItem(testKey, testKey);
      storage.removeItem(testKey);

      return true;
    } catch (e) {
      // If an error is thrown, check if it's a quota error.
      // The logic below is commonly used to distinguish storage availability issues.
      return e instanceof DOMException && (
        // Everything except Firefox
        e.code === 22
        // Firefox
        || e.code === 1014
        // Test name field too, because code might not be present
        // Everything except Firefox
        || e.name === 'QuotaExceededError'
        // Firefox
        || e.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      ) && (storage && storage.length !== 0);
    }
  };

  return {
    localStorageAvailable,
  };
}
