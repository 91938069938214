import { DateTime } from 'luxon';

import { i18n } from '@/i18n';

export default function useAppointmentDatetimeFormat() {
  const getAppointmentDate = (timestamp, timezone, huge) => DateTime.fromISO(timestamp, { zone: 'utc' })
    .setZone(timezone)
    .setLocale(i18n.locale)
    .toLocaleString(huge ? DateTime.DATE_HUGE : DateTime.DATE_MED);

  const getAppointmentDateWithWeekday = (timestamp, timezone) => DateTime.fromISO(timestamp, { zone: 'utc' })
    .setZone(timezone)
    .setLocale(i18n.locale)
    .toLocaleString({
      weekday: 'long',
      month: 'short',
      day: 'numeric',
    });

  const getAppointmentTime = (timestamp, timezone) => DateTime.fromISO(timestamp, { zone: 'utc' })
    .setLocale(i18n.locale)
    .setZone(timezone)
    .toLocaleString(DateTime.TIME_SIMPLE);

  const getTimeAsTwentyFourHours = (timestamp) => DateTime.fromISO(timestamp)
    .setLocale(i18n.locale)
    .toUTC()
    .toLocaleString(DateTime.TIME_24_SIMPLE);

  const getAppointmentTimeRange = (timeslot, timezone, hideEnd) => {
    if (hideEnd) {
      return getAppointmentTime(timeslot.start_at, timezone);
    }

    return `${getAppointmentTime(
      timeslot.start_at,
      timezone,
    )} - ${getAppointmentTime(timeslot.end_at, timezone)}`;
  };

  return {
    getAppointmentDate,
    getAppointmentDateWithWeekday,
    getAppointmentTimeRange,
    getAppointmentTime,
    getTimeAsTwentyFourHours,
  };
}
