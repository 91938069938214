import useMembershipPromotion from '@/composables/use-membership-promotion';
import SubscriptionPlanType from '@/enums/SubscriptionPlanType';

export default {
  namespaced: true,

  state: {
    promotion: null,
    yearlySubscriptionPlan: null,
  },

  getters: {
    hasPromotion(state) {
      return !!state.promotion?.isEnabled;
    },
    hasYearlySubscriptionPlan(state) {
      return !!state.yearlySubscriptionPlan;
    },
    getYearlySubscriptionPlan(state) {
      return state.yearlySubscriptionPlan;
    },
  },

  mutations: {
    setPromotion(state, promotion) {
      state.promotion = promotion;
    },
    setYearlySubscriptionPlan(state, plan) {
      state.yearlySubscriptionPlan = plan;
    },
  },

  actions: {
    async fetchSubscriptionPlan({ commit, dispatch }, filter = { type: SubscriptionPlanType.Membership }) {
      const response = await this.$app.$api.get('/p/subscription-plans', {
        params: {
          filter,
        },
      });

      commit('setYearlySubscriptionPlan', response.data.data[0]);
      await dispatch('fetchPromotion');
    },
    fetchPromotion({ commit, getters }) {
      const membershipPromotion = useMembershipPromotion(this.$app, getters.getYearlySubscriptionPlan);
      if (membershipPromotion.promotion) {
        commit('setPromotion', membershipPromotion.promotion);
      }
    },
  },
};
