import { StatsigPlugin } from '@vetster/statsig-vue';

import usePvarsStorage from '@/composables/use-pvars-storage';
import { analyticsDefaultPayload } from '@/helpers/analytics';

const { latestPvars } = usePvarsStorage();

const addCustomIds = (payload, uatId) => {
  if (uatId) {
    payload.customIDs = { uatId };
  }

  return payload;
};

const getDeviceType = () => {
  if ('ontouchstart' in document.documentElement) {
    return 'MOBILE_WEB';
  }

  return 'DESKTOP_WEB';
};

const buildGuestUser = (uatId) => {
  const pvars = latestPvars();

  return addCustomIds({
    country: window.__VETSTER.geo.country,
    locale: window.__VETSTER.locale,
    userAgent: window.navigator.userAgent,
    custom: {
      platform: 'WEB',
      platform_device: getDeviceType(),
      type: 'anonymous',
      city: window.__VETSTER.geo.city,
      region: window.__VETSTER.geo.region,
      ...pvars,
    },
  }, uatId);
};

export function buildStatsigUser(uatId, { user, memberships }) {
  const pvars = latestPvars();

  return addCustomIds({
    userID: user.uuid,
    country: window.__VETSTER.geo.country,
    userAgent: window.navigator.userAgent,
    locale: user.locale,
    custom: {
      platform: 'WEB',
      platform_device: getDeviceType(),
      kind: 'user',
      organization: user.organization?.name,
      type: user.isClient() ? 'client' : 'partner',
      city: window.__VETSTER.geo.city,
      region: window.__VETSTER.geo.region,
      email_hash: user.email_hash_sha1,
      language: user.locale === 'fr-CA' ? 'FR' : 'EN',
      memberships,
      ...pvars,
    },
    privateAttributes: {
      email: user.email,
      firstName: user.first_name,
      lastName: user.last_name,
    },
  }, uatId);
}

export function initStatsig(Vue, uat) {
  Vue.use(StatsigPlugin, {
    clientId: import.meta.env.VITE_STATSIG_CLIENT_ID,
    clientOptions: {
      api: import.meta.env.VITE_STATSIG_API,
      environment: {
        tier: import.meta.env.VITE_STATSIG_ENVIRONMENT,
      },
    },
    evaluationCallback: (type, detail) => {
      window.FS?.event(`Statsig ${type} check`, detail);
    },
    logEventCallback: (name, value, metadata) => ({
      ...analyticsDefaultPayload(),
      ...metadata,
    }),
    user: buildGuestUser(uat),
  });
}
