import trimEnd from 'lodash/trimEnd';
import trimStart from 'lodash/trimStart';

import {
  getLocaleSlugs,
  i18n,
} from '@/i18n';

const getAppUrl = (path, params = {}) => {
  const trimmedPath = `${import.meta.env.VITE_WEB_APP_URL}${trimStart(trimEnd(path, '/'), '/')}`;

  const searchParams = new URLSearchParams({
    locale: i18n.locale,
    ...params,
  });

  return `${trimmedPath}?${searchParams.toString()}`;
};

const getAssetUrl = (path) => `${import.meta.env.VITE_ASSET_URL}/${trimStart(path, '/')}`;

const getUrl = (path) => `${import.meta.env.VITE_APP_URL}${trimStart(trimEnd(path, '/'), '/')}`;

const getQueryParams = () => {
  try {
    return Object.fromEntries(new URLSearchParams(window.location.search).entries());
  } catch {
    return {};
  }
};

/**
 * The current view doesn't contain Blade template conditionals.
 * e.g., content restricted from view for the Rest of World global experience.
 */
const isSPA = (path) => /^\/(en(-ca|-gb|-us)?|fr(-ca)?)\/gs/.test(path);

const getQueryParam = (key) => getQueryParams()[key] ?? null;

const removeLocaleFromPath = (path) => {
  const locales = `(${getLocaleSlugs().join('|')})`;

  return `/${trimStart(path, '/').replace(new RegExp(`^(${locales}/)|(${locales}$)`, 'i'), '')}`;
};

const slugify = (str) => {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz-----';
  const p = new RegExp(a.split('').join('|'), 'g');

  return (str ?? '').toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/--+/g, '-') // Replace multiple - with single -
    .replace(/[^\w-]+/g, '') // Remove all non-word characters
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, ''); // Trim - from end of text
};

export {
  getAppUrl,
  getAssetUrl,
  getQueryParam,
  getQueryParams,
  getUrl,
  isSPA,
  removeLocaleFromPath,
  slugify,
};
