import emitter from 'tiny-emitter/instance';

import setGlobalProperty from '@/helpers/setGlobalProperty';

export default {
  install(app) {
    setGlobalProperty(app, '$bus', {
      $on: (...args) => emitter.on(...args),
      $off: (...args) => emitter.off(...args),
      $emit: (...args) => emitter.emit(...args),
    });
  },
};
