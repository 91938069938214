import { computed } from 'vue';

export default function useMembershipPromotion(root, plan) {
  // To enable a future promotion, Vuex fetchPromotion() must call
  // commit('setPromotion', useMembershipPromotion().promotion);
  // to configure below.
  // const isPromotionEnabled = false; // computed(() => root.$statsig.ready && root.$statsig.checkGate('checkgate'))

  const isPromotionEnabled = computed(() => root.$statsig.ready && root.$statsig.checkGate('subs_black_friday_promotion'));

  const promotionPrices = computed(() => {
    const config = root.$statsig.ready && root.$statsig.getConfig('subs_vetster_plus_promotion_price')?.value;

    if (config?.uuid === plan?.uuid) {
      return {
        customAnnualPrice: config.annualPrice,
        customMonthlyPrice: config.monthlyPrice,
      };
    }

    return {
      customAnnualPrice: undefined,
      customMonthlyPrice: undefined,
    };
  });

  const prices = computed(() => {
    const { customAnnualPrice, customMonthlyPrice } = promotionPrices.value;
    const annualPrice = plan?.product?.price?.amount;
    const monthlyPrice = Math.round((annualPrice / 12) * 100) / 100;
    const originalAnnualPrice = plan?.product?.msrp?.amount || (customAnnualPrice ? annualPrice : null);

    return {
      annualPrice: customAnnualPrice || annualPrice,
      monthlyPrice: customMonthlyPrice || monthlyPrice,
      originalAnnualPrice,
    };
  });

  const promotion = computed(() => {
    const {
      annualPrice,
      monthlyPrice,
      originalAnnualPrice,
    } = prices.value;

    return {
      isEnabled: originalAnnualPrice > annualPrice,
      annualPrice: { amount: annualPrice },
      monthlyPrice: { amount: monthlyPrice },
      originalAnnualPrice: { amount: originalAnnualPrice },
      originalMonthlyPrice: { amount: originalAnnualPrice / 12 },
    };
  });

  return {
    promotion,
    isPromotionEnabled,
  };
}
