/* eslint-disable dot-notation */
import omit from 'lodash/omit';

import { fullStoryTrack } from '@/packages/FullStory';
import googleAnalyticsTrack from '@/packages/googleAnalyticsTrack';

const defaultPayload = {
  platform: 'cx-web',
};

const events = {};

class Analytics {
  constructor(statsig) {
    this.statsig = statsig;
  }

  track(originalPayload, newPayload = {}) {
    const eventName = originalPayload.eventName || originalPayload.event;

    if (events[eventName]) {
      const payload = events[eventName](originalPayload, newPayload);

      if (payload?.ga) {
        googleAnalyticsTrack(payload.ga);
      }

      if (payload?.fs) {
        fullStoryTrack(payload.fs.eventName, payload.fs);
      }

      if (payload?.statsig) {
        this.statsig().logEvent(
          payload.statsig.eventName,
          null,
          omit(payload.statsig, ['eventName']),
        );
      }
    }

    // Always send original payload to Google Analytics
    googleAnalyticsTrack(originalPayload);
  }

  trackPageLoad() {
    this.track({
      platform: 'cx-web',
      parent: 'global',
      child: 'webpage',
      event: 'ga_event',
      eventName: 'page_load',
    });
  }
}

events['gx:identity:login:submit-success'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'user_sign_in',
    parent: 'auth',
    child: 'sign-in',
    event_status: 'success',
    type: newPayload?.type,
  };

  return {
    ga: {
      ...payload,
      event: 'ga_event',
    },
    fs: payload,
    statsig: payload,
  };
};

events['gx:identity:login:submit-fail'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'user_sign_in',
    parent: 'auth',
    child: 'sign-in',
    event_status: 'failure',
    type: newPayload?.type,
  };

  return {
    ga: {
      ...payload,
      event: 'ga_event',
    },
    fs: payload,
    statsig: payload,
  };
};

events['gx:identity:register:submit-success'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'user_sign_up',
    parent: 'auth',
    child: 'sign-up',
    event_status: 'success',
    type: newPayload?.type,
  };

  return {
    ga: {
      ...payload,
      event: 'ga_event',
    },
    fs: payload,
    statsig: payload,
  };
};

events['gx:identity:register:submit-error'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'user_sign_up',
    parent: 'auth',
    child: 'sign-up',
    event_status: 'failure',
    type: newPayload?.type,
  };

  return {
    ga: {
      ...payload,
      event: 'ga_event',
    },
    fs: payload,
    statsig: payload,
  };
};

events['logout'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'user_sign_out',
    parent: 'auth',
    child: 'sign-out',
  };

  return {
    ga: {
      ...payload,
      event: 'ga_event',
    },
    fs: payload,
    statsig: payload,
  };
};

events['content_gate_load'] = (originalPayload, newPayload) => {
  const payload = {
    ...defaultPayload,
    ...newPayload,
    eventName: 'content_gate_load',
    parent: 'content-page',
    child: 'content-gate',
  };

  return {
    fs: payload,
    statsig: payload,
  };
};

events['content_gate_click'] = (payload) => ({
  fs: payload,
  statsig: payload,
});

events['global_search_result_click'] = (payload) => ({
  fs: payload,
  statsig: payload,
});

events['global_search_click'] = (payload) => ({
  fs: payload,
  statsig: payload,
});

events['house_ad_view'] = (originalPayload) => {
  const payload = {
    ...defaultPayload,
    ...originalPayload,
  };

  return {
    statsig: payload,
  };
};

events['house_ad_click'] = (originalPayload) => {
  const payload = {
    ...defaultPayload,
    ...originalPayload,
  };

  return {
    statsig: payload,
  };
};

events['house_ad_dismiss'] = (originalPayload) => {
  const payload = {
    ...defaultPayload,
    ...originalPayload,
  };

  return {
    statsig: payload,
  };
};

events['page_load'] = (originalPayload) => {
  const payload = {
    ...defaultPayload,
    ...originalPayload,
  };

  return {
    statsig: payload,
  };
};

export default {
  install(app) {
    const globalProperties = app.config?.globalProperties || app.prototype;

    globalProperties.$analytics = new Analytics(() => globalProperties.$statsig);
  },
};
