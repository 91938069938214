import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';

import SocialiteProvider from '@/enums/SocialiteProvider';
import { getCookie } from '@/helpers/cookies';
import { getQueryParam } from '@/helpers/url';

export default {
  namespaced: true,

  state: {
    apple: {},
    facebook: {},
    google: {},
    registerWithSocialite: {
      provider_type: '',
      first_name: '',
      last_name: '',
      email: '',
      profile_photo: '',
      access_token: '',
    },
    loading: false,
  },

  getters: {
    getGoogle(state) {
      return state.google;
    },
    canConnectGoogle(state) {
      return isEmpty(state.google);
    },
    canRemoveGoogle(state) {
      return !isEmpty(state.google);
    },
    getFacebook(state) {
      return state.facebook;
    },
    canConnectFacebook(state) {
      return isEmpty(state.facebook);
    },
    canRemoveFacebook(state) {
      return !isEmpty(state.facebook);
    },
    getRegisterWithSocialite(state) {
      return state.registerWithSocialite;
    },
    getRegistrationSource() {
      return (getQueryParam('utm_campaign') || getCookie('v-utm-campaign'))?.toUpperCase();
    },
    isOpenForRegistration(state) {
      return !isEmpty(state.registerWithSocialite.access_token);
    },
    hasProfilePhoto(state) {
      return !isEmpty(state.registerWithSocialite.profile_photo);
    },
    isLoading(state) {
      return state.loading;
    },
  },

  mutations: {
    setApple(state, data) {
      state.apple = data;
    },
    setGoogle(state, data) {
      state.google = data;
    },
    setFacebook(state, data) {
      state.facebook = data;
    },
    setRegisterWithSocialite(state, data) {
      state.registerWithSocialite.provider_type = data.provider_type;
      state.registerWithSocialite.first_name = data.first_name;
      state.registerWithSocialite.last_name = data.last_name;
      state.registerWithSocialite.email = data.email;
      state.registerWithSocialite.profile_photo = data.profile_photo;
      state.registerWithSocialite.access_token = data.access_token;
    },
    emptyRegisterWithSocialite(state) {
      state.registerWithSocialite.provider_type = '';
      state.registerWithSocialite.first_name = '';
      state.registerWithSocialite.last_name = '';
      state.registerWithSocialite.email = '';
      state.registerWithSocialite.profile_photo = '';
      state.registerWithSocialite.access_token = '';
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },

  actions: {
    async providerSdkLogout({ commit }, payload) {
      const { component } = payload;

      switch (payload.provider_type) {
        case SocialiteProvider.google.value:
          if (component.$gAuth?.isInit && component.$gAuth.isAuthorized) {
            await component.$gAuth.signOut();
          }

          commit('setGoogle', {});

          break;
        case SocialiteProvider.facebook.value:
          if (window.FB?.getAccessToken?.()) {
            await new Promise(window.FB.logout);
          }

          commit('setFacebook', {});

          break;
        case SocialiteProvider.apple.value:
          // `window.AppleID.auth` doesn't have a sign-out method.

          commit('setApple', {});

          break;
        default:
          break;
      }
    },

    async register({
      rootState,
    }, payload) {
      await rootState.$auth.registerViaSocialiteV2(payload);
    },

    async fetchProviders({
      rootState,
      commit,
    }) {
      const response = await rootState.$auth.getUserProviders();
      commit('setApple', response.data.data.find((provider) => provider.provider_type === 'apple'));
      commit('setGoogle', response.data.data.find((provider) => provider.provider_type === 'google'));
      commit('setFacebook', response.data.data.find((provider) => provider.provider_type === 'facebook'));
    },

    async storeSocialite({
      rootState,
      commit,
    }, payload) {
      const response = await rootState.$auth.storeSocialite(payload);
      commit('setApple', response.data.data.find((provider) => provider.provider_type === 'apple'));
      commit('setGoogle', response.data.data.find((provider) => provider.provider_type === 'google'));
      commit('setFacebook', response.data.data.find((provider) => provider.provider_type === 'facebook'));
      commit('emptyRegisterWithSocialite');
    },

    async removeSocialite({
      rootState,
      dispatch,
    }, payload) {
      await rootState.$auth.removeSocialite(payload);
      dispatch('fetchProviders');
    },

    async authenticate({
      rootState,
      commit,
    }, payload) {
      const response = await rootState.$auth.authSocialite(payload);
      if (response.data.data.provider_type) {
        const { data } = merge(response.data, { data: payload });
        commit('setRegisterWithSocialite', data);
        commit('setXsrfToken', response, { root: true });
      }

      return response;
    },

    storeProfilePhoto(context, payload) {
      this.$app.$api.post('v1/profile/photo', payload);
    },
  },
};
